export const howImetYourMotherList = [
  {
    title: 'The One Where Underdog Gets Away',
    season: '1',
    episode: '9',
    thumbnail: '',
    rating: 4,
    holidays: ['thanksgiving']
  },
]
// https://how-i-met-your-mother.fandom.com/wiki/Category:Holiday_episodes